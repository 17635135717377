<template>
  <div class="font-stolzl">

  <!-- BACKGROUND -->
  <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
    <div v-if="navigating || selectingSize || hover !== '' || switchedChannel" class="w-screen h-screen fixed bg-black bg-opacity-60 inset-0 z-40" @click="resetBlur()"></div>
  </transition>
  <!-- BACKGROUND -->

  <!-- MENU OPEN -->
    <div id="menu" :class="{'hidden' : !navigatingMobile}" class="fixed top-0 z-50 bg-coolGray-50 w-screen h-screen flex flex-col justify-between text-coolGray-900 text-4xl p-4 font-normal" >
      <div class="flex flex-col">

        <router-link :to="{ name: 'Home' }">Home</router-link>

        <!-- SHOP -->
        <div>
          <div class="flex flex-row justify-start items-center"  @click="toggleTab('Shop')">
            <div>Shop</div>
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-500 ease-in-out h-6 w-6 text-coolGray-900 ml-1 -mb-2" :class="{'transition duration-500 ease-in-out transform rotate-180' : currentTab == 'Shop'}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <transition @enter="fadeInGrowEnter2" @leave="fadeInGrowLeave2" :css="false">
          <div v-if="currentTab == 'Shop' && categories?.categories" class="pl-8 py-2">
            <div v-for="category in categories.categories" :key="category" @click="navigate(category.id, category.slug)">
              <div class="text-lg font-light py-1">{{category.name}}</div>
            </div>
          </div>
          </transition>
        </div>
        <!-- SHOP -->

        <!-- RE: -->
        <div>
          <div class="flex flex-row justify-start items-center"  @click="toggleTab('Re:')">
            <div>Re:</div>
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-500 ease-in-out h-6 w-6 text-coolGray-900 ml-1 -mb-2" :class="{'transition duration-500 ease-in-out transform rotate-180' : currentTab == 'Re:'}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <transition @enter="fadeInGrowEnter2" @leave="fadeInGrowLeave2" :css="false">
          <div v-if="currentTab == 'Re:'" class="pl-8 py-2">
            <router-link :to="{ name: 'Team' }">
              <div class="text-lg font-light py-1">Team</div>
            </router-link>
            <router-link :to="{ name: 'Content', params: { slug: 'ueber-uns' } }">
              <div class="text-lg font-light py-1">{{text.about}}</div>
            </router-link>
            <router-link :to="{ name: 'Content', params: { slug: 'ueber-chalk' } }">
              <div class="text-lg font-light py-1">{{text.aboutChalk}}</div>
            </router-link>
            <router-link :to="{ name: 'Content', params: { slug: 'nachhaltigkeit' } }">
              <div class="text-lg font-light py-1">{{text.sus}}</div>
            </router-link>
            <a href="https://www.instagram.com/reclimb_app/" target="_blank">
              <div class="text-lg font-light py-1">Re:climb App</div>
            </a>
          </div>
          </transition>
        </div>
        <!-- RE: -->

        <!-- SERVICE -->
        <div>
          <div class="flex flex-row justify-start items-center"  @click="toggleTab('Service')">
            <div>Service</div>
            <svg xmlns="http://www.w3.org/2000/svg" class="transition duration-500 ease-in-out h-6 w-6 text-coolGray-900 ml-1 -mb-2" :class="{'transition duration-500 ease-in-out transform rotate-180' : currentTab == 'Service'}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </div>
          <transition @enter="fadeInGrowEnter2" @leave="fadeInGrowLeave2" :css="false">
          <div v-if="currentTab == 'Service'" class="pl-8 py-2">
            <router-link :to="{ name: 'Content', params: { slug: 'b2b' } }">
              <div class="text-lg font-light py-1">{{text.b2bCustomer}}</div>
            </router-link>
            <router-link :to="{ name: 'Content', params: { slug: 'dropshipping' } }">
              <div class="text-lg font-light py-1">Dropshipping</div>
            </router-link>
            <router-link :to="{ name: 'Content', params: { slug: 'textilveredelung' } }">
              <div class="text-lg font-light py-1">{{text.screenPrint}}</div>
            </router-link>
            <router-link :to="{ name: 'Content', params: { slug: 'kontakt' } }">
              <div class="text-lg font-light py-1">{{text.contact}}</div>
            </router-link>
          </div>
          </transition>
        </div>
        <!-- SERVICE -->

        <!-- LANGUAGE -->
        <div v-if="fallbackLang == 'DE'" @click="changeLang(fallbackLang)">Deutsch</div>
        <div v-else  @click="changeLang(fallbackLang)">English</div>
        <!-- LANGUAGE -->

        <!-- B2B -->
        <div v-if="channel == 'b2c'" @click="setChannel({channel: 'b2b', switch: false})">B2B</div>
        <!-- B2B -->

        <!-- B2B -->
        <div v-if="channel == 'b2b'">
          <router-link :to="{ name: 'Account' }">
            <div>Account</div>
          </router-link>
        </div>
        <!-- B2B -->

        <!-- UTILITY -->
        <div class="w-full border-t border-coolGray-700 mt-5 text-sm flex flex-row flex-wrap font-light pt-2">
          <router-link :to="{ name: 'Content', params: { slug: 'kontakt' } }" class="pr-4">{{text.contact}}</router-link>
          <router-link :to="{ name: 'AGB' }" class="pr-4">{{text.agb}}</router-link>
          <router-link :to="{ name: 'Datenschutz' }" class="pr-4">{{text.data}}</router-link>
          <router-link :to="{ name: 'Impressum' }" class="pr-4">{{text.imprint}}</router-link>
          <router-link :to="{ name: 'Widerrufsbelehrung' }" class="pr-4">{{text.wie}}</router-link>
          <a href="https://www.instagram.com/rewhiteclimbing/" target="_blank">Instagram</a>

        </div>
        <!-- UTILITY -->

        <!-- B2B INFO -->
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="channel == 'b2b'" class="text-sm my-5 text-red-600">
            {{text.b2bInfo1}}
            <div class="text-coolGray-900 mt-1" @click="setChannel({channel: 'b2c', switch: false})">{{text.back}}</div>
          </div>
        </transition>
        <!-- B2B INFO -->

      </div>
      <div class="mb-32 flex flex-row justify-center items-center">
        <div class="w-12 h-12 rounded-full flex flex-row justify-center items-center text-4xl font-light text-coolGray-900" @click="closeMenu">X</div>
      </div>
    </div>
  <!-- MENU OPEN -->

  <!-- DESKTOP BAR -->
  <div v-if="this.$route.name !== 'Checkout'" id="nav" class="transition duration-500 ease-in-out text-coolGray-300 hidden sm:fixed sm:w-screen sm:z-50 sm:flex sm:flex-row sm:justify-center sm:items-center sm:top-0 sm:px-0 sm:h-16 sm:select-none" :class="{'transition duration-500 ease-in-out text-coolGray-900' : navBarBackground}">
    <div class="transition duration-500 ease-in-out w-full h-full  bg-transparent flex flex-row justify-between items-center px-8" :class="{'transition duration-500 ease-in-out bg-white' : navBarBackground}">

      <div class="flex flex-row justify-between items-center">
        <!-- LOGO -->
        <div @click="goHomeOrScroll" class="cursor-pointer">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200.1 35.35" class="w-32 h-auto  mr-10" fill="currentColor" stroke="none">
            <g id="Ebene_2" data-name="Ebene 2"><g id="design"><path class="cls-1" d="M17.41,7.72v8.44a13.8,13.8,0,0,0-1.64-.1c-3.86,0-7.57,1.16-7.57,7.18V34.72H0V8.2H8.2v4.1a8.14,8.14,0,0,1,7.71-4.73A6.08,6.08,0,0,1,17.41,7.72Z"/><path class="cls-1" d="M47.4,23.2H28.06c.38,4.1,3,5.83,5.83,5.83a4.6,4.6,0,0,0,4.68-3H47c-1.83,6.56-7.38,9.31-13.12,9.31-8.48,0-14.18-6-14.18-13.89a13.51,13.51,0,0,1,14-13.89C42.48,7.57,48.22,14.47,47.4,23.2Zm-19.1-5H38.86a5,5,0,0,0-5.21-4.34A5.27,5.27,0,0,0,28.3,18.23Z"/><path class="cls-1" d="M60.86,13.84a5,5,0,1,1-5-4.73A4.71,4.71,0,0,1,60.86,13.84Zm0,16.78a5,5,0,1,1-5-4.72A4.71,4.71,0,0,1,60.86,30.62Z"/><path class="cls-1" d="M104.75,8.2l-7,26.52h-9.4L85.94,25.8l-1.35-9.55h-.82L82.42,25.8l-2.36,8.92h-9.4l-7-26.52h8.11l2.12,8.34,1.69,8.78,1.64-8.78L79.34,8.2H89l2.12,8.34,1.69,8.68,1.64-8.68L96.65,8.2Z"/><path class="cls-1" d="M133.23,18.13V34.72H125V20c0-2.89-.87-4.58-4.15-4.58s-4.82,1.59-4.82,5.26V34.72h-8.2V0h8.2V11.19a9.32,9.32,0,0,1,7.67-3.62C129.61,7.57,133.23,11.67,133.23,18.13Z"/><path class="cls-1" d="M138.24,8.2h8.2V34.72h-8.2Z"/><path class="cls-1" d="M169.87,33a16.34,16.34,0,0,1-8.29,2.32c-5.12,0-10.13-3-10.13-10.9V0h8.2V8.2h8.92v7.71h-8.92V24c0,2.55,1.3,3.76,3.37,3.76a20.19,20.19,0,0,0,5.45-1.3Z"/><path class="cls-1" d="M200,23.2H180.68c.39,4.1,3,5.83,5.84,5.83a4.6,4.6,0,0,0,4.68-3h8.44c-1.84,6.56-7.38,9.31-13.12,9.31-8.49,0-14.18-6-14.18-13.89a13.51,13.51,0,0,1,14-13.89C195.1,7.57,200.84,14.47,200,23.2Zm-19.1-5h10.57a5,5,0,0,0-5.21-4.34C183.34,13.89,181.6,15.67,180.92,18.23Z"/></g></g>
          </svg>
        </div>
        <!-- LOGO -->

        <!-- DEEP NAV LEFT -->
        <div class="flex flex-row justify-center items-end h-8" @mouseleave="hover = ''">

          <!-- SHOP -->
          <div class="relative">
            <div class="group flex flex-row justify-center items-center mr-10 cursor-pointer" @click="browseNav('shop')" @mouseover="hover = 'shop'">
              <div class="font-stolzl font-normal  text-sm mr-1 group-hover:text-coolGray-800">Shop</div>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-3  pt-0.5 transition duration-100 ease-in-out transform-gpu group-hover:text-coolGray-800 group-hover:translate-y-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
              <div v-if="navigating && browse == 'shop' || hover == 'shop'" class="absolute font-stolzl font-normal text-coolGray-900 text-sm pt-12" @mouseover="hover = 'shop'">
                <div class="relative bg-white px-8 py-4 rounded shadow-md">
                  <div class="ml-8 w-4 h-4 bg-white rounded-sm absolute inset-x-0 -top-1 transform-gpu rotate-45"></div>
                  <div v-if="categories?.categories">
                  <div v-for="category in categories.categories" :key="category" @click="navigate(category.id, category.slug)">
                    <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{category.name}}</div>
                  </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <!-- SHOP -->

          <!-- RE -->
          <div class="relative">
            <div class="group flex flex-row justify-center items-center mr-10 cursor-pointer" @click="browseNav('re:')" @mouseover="hover = 're:'">
              <div class="font-stolzl font-normal  text-sm mr-1 group-hover:text-coolGray-800">Re:</div>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-3  pt-0.5 transition duration-100 ease-in-out transform-gpu group-hover:text-coolGray-800 group-hover:translate-y-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
              <div v-if="navigating && browse == 're:'  || hover == 're:'" class="absolute font-stolzl font-normal text-coolGray-900 text-sm pt-12">
                <div class="relative bg-white px-8 py-4 rounded shadow-md">
                  <div class="ml-8 w-4 h-4 bg-white rounded-sm absolute inset-x-0 -top-1 transform-gpu rotate-45"></div>
                    <router-link :to="{ name: 'Content', params: { slug: 'ueber-uns' } }" @click="navigating = false, this.hover = ''">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{text.about}}</div>
                    </router-link>
                    <router-link :to="{ name: 'Team' }">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">Team</div>
                    </router-link>
                    <router-link :to="{ name: 'Content', params: { slug: 'nachhaltigkeit' } }" @click="navigating = false, this.hover = ''">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{text.sus}}</div>
                    </router-link>
                    <router-link :to="{ name: 'Content', params: { slug: 'ueber-chalk' } }" @click="navigating = false, this.hover = ''">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{text.aboutChalk}}</div>
                    </router-link>
                    <a href="https://www.instagram.com/reclimb_app/" target="_blank">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">Re:climb App</div>
                    </a>
                </div>
              </div>
            </transition>
          </div>
          <!-- RE -->

          <!-- SERVICE -->
          <div class="relative">
            <div class="group flex flex-row justify-center items-center mr-10 cursor-pointer" @click="browseNav('service')" @mouseover="hover = 'service'">
              <div class="font-stolzl font-normal  text-sm mr-1 group-hover:text-coolGray-800">Service</div>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-3  pt-0.5 transition duration-100 ease-in-out transform-gpu group-hover:text-coolGray-800 group-hover:translate-y-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
              <div v-if="navigating && browse == 'service'  || hover == 'service'" class="absolute font-stolzl font-normal text-coolGray-900 text-sm pt-12">
                <div class="relative bg-white px-8 py-4 rounded shadow-md">
                  <div class="ml-8 w-4 h-4 bg-white rounded-sm absolute inset-x-0 -top-1 transform-gpu rotate-45"></div>
                    <router-link :to="{ name: 'Content', params: { slug: 'b2b' } }" @click="navigating = false, this.hover = ''">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{text.b2bCustomer}}</div>
                    </router-link>
                    <router-link :to="{ name: 'Content', params: { slug: 'dropshipping' } }" @click="navigating = false, this.hover = ''">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">Dropshipping</div>
                    </router-link>
                    <router-link :to="{ name: 'Content', params: { slug: 'textilveredelung' } }" @click="navigating = false, this.hover = ''">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{text.screenPrint}}</div>
                    </router-link>
                    <router-link :to="{ name: 'Content', params: { slug: 'kontakt' } }" @click="navigating = false, this.hover = ''">
                      <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{text.contact}}</div>
                    </router-link>
                </div>
              </div>
            </transition>
          </div>
          <!-- SERVICE -->

          <!-- LANGUAGE -->
          <div class="relative">
            <div class="group flex flex-row justify-center items-center mr-10 cursor-pointer" @click="browseNav('language')" @mouseover="hover = 'language'">
              <div class="font-stolzl font-normal  text-sm mr-1 group-hover:text-coolGray-800">{{lang}}</div>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-3  pt-0.5 transition duration-100 ease-in-out transform-gpu group-hover:text-coolGray-800 group-hover:translate-y-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
              <div v-if="navigating && browse == 'language' || hover == 'language'" class="absolute font-stolzl font-normal text-coolGray-900 text-sm pt-12">
                <div class="relative bg-white px-8 py-4 rounded shadow-md">
                  <div class="ml-8 w-4 h-4 bg-white rounded-sm absolute inset-x-0 -top-1 transform-gpu rotate-45"></div>
                    <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1" @click="changeLang(fallbackLang)">{{fallbackLang}}</div>
                </div>
              </div>
            </transition>
          </div>
          <!-- LANGUAGE -->

        </div>
        <!-- DEEP NAV LEFT-->
      </div>

      <div class="flex flex-row justify-end items-center">

        <!-- B2B SWITCH -->
        <div>
          <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false" mode="out-in">
            <div v-if="channel == 'b2c'" class="h-8 flex items-end font-stolzl font-normal  text-sm mr-5 cursor-pointer" @click="setChannel({channel: 'b2b', switch: true})">{{text.b2b}}</div>
            <div v-else class="h-8 flex items-end  font-stolzl font-normal  text-sm mr-5 cursor-pointer" @click="setChannel({channel: 'b2c', switch: true})">{{text.back2}}</div>
          </transition>
          <div v-if="switchedChannel" class="fixed z-50 inset-0 w-screen h-screen flex flex-row justify-center items-center">
            <div class="w-6/12 h-auto max bg-white font-stolzl font-light text-coolGray-900 text-sm rounded shadow-md p-8">
              <div class="font-bold text-3xl text-coolGray-900 mb-2">B2B</div>
              <p class="mb-1 font-light text-base text-coolGray-900">{{text.b2bInfo2}}</p>
              <p class="mb-1 font-light text-base text-coolGray-900">{{text.b2bInfo3}}</p>
              <p class="mb-1 font-light text-base text-coolGray-900">{{text.b2bInfo4}}</p>
              <div class="flex flex-row justify-between items-center mt-5">
                <div class="transition duration-500 ease-in-out w-1/4 mr-2 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2 text-center" @click="switchedChannel = false">OK</div>
                <div class="transition duration-500 ease-in-out w-3/4 bg-coolGray-900 h-16 font-stolzl font-light text-white flex flex-row justify-center items-center border-coolGray-900 cursor-pointer hover:bg-white hover:text-coolGray-900 border-2 text-center" @click="setChannel({channel: 'b2c', switch: true})">{{text.back}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- B2B SWITCH -->

        <!-- B2B ACCOUNT-->
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false" mode="out-in">
        <div v-if="channel == 'b2b'" class="flex flex-row justify-center items-end h-8 bg-coolGray-100 rounded-lg mr-2 -mb-1 py-1 px-2">

          <router-link :to="{name: 'Account'}" class="relative">
            <div class="group flex flex-row justify-center items-center cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-coolGray-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
              </svg>
            </div>
          </router-link>

        </div>
        </transition>
        <!-- B2B ACCOUNT-->

        <!-- CART -->
        <div class="pr-2 pt-0.5">
          <router-link :to="{name: 'Cart'}">
            <div class="relative cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 " fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
              </svg>
              <div v-if="cart?.items.length !== 0" class="absolute -bottom-0.5 -right-0 w-2 h-2" :class="{'animate-ping' : addingItem}">
                <div id="cartIndicator" class="w-full h-full rounded-full bg-coolGray-200" :class="{'transition duration-500 ease-in-out bg-coolGray-900' : navBarBackground}"></div>
              </div>
            </div>
          </router-link>
        </div>
        <!-- CART -->

      </div>

    </div>
  </div>
  <!-- DESKTOP BAR -->

  <!-- MOBILE BAR -->
  <div v-if="!hideNav && !checkoutCompleted" id="bar" class="fixed bottom-10 h-28 w-screen z-40 flex flex-row justify-center items-center px-2 sm:hidden">
    <div id="mobileBar" class="relative w-full h-16 bg-coolGray-900 rounded-2xl shadow flex flex-row justify-between items-center px-4">

      <!-- MENU -->
      <div v-if="this.$route.name !== 'Checkout'" class="relative py-2 font-stolzl font-normal text-coolGray-50 text-sm flex flex-col" @click="showMenu">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </div>
      <!-- MENU -->

      <!-- ACCOUNT NAV -->
      <div v-if="this.$route.name == 'Account' && auth" class="bg-coolGray-800 rounded-xl py-2 font-stolzl font-normal text-coolGray-50 text-sm flex flex-col">
        <div class="group flex flex-row justify-center items-center mr-3 cursor-pointer" @click="browseNav('account')">
          <div class="pl-4 pr-2">{{text.more}}</div>
        </div>
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="navigating && browse == 'account'" class="absolute inset-x-0 w-full bottom-16 mb-3 font-stolzl font-normal text-coolGray-50 text-sm pt-12">
            <div class="relative bg-coolGray-900 px-8 py-4 rounded-2xl shadow-md">
              <div class="absolute w-full -bottom-1 inset-x-0 flex flex-row justify-center items-center">
                <div class="w-4 h-4 bg-coolGray-900 rounded-sm transform-gpu rotate-45"></div>
              </div>
              <div v-for="tab in currentAccountTab" :key="tab" @click="setTab(tab)">
                <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{tab}}</div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- ACCOUNT NAV -->

      <!-- LANGUAGE SWITCH-->
      <div v-if="this.$route.name == 'Home'" class="bg-coolGray-800 rounded-xl py-2 font-stolzl font-normal text-coolGray-50 text-sm flex flex-col">
        <div class="group flex flex-row justify-center items-center mr-3 cursor-pointer" @click="browseNav('home')">
          <div class="pl-4 pr-2">{{text.changeLang}}</div>
        </div>
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="navigating && browse == 'home'" class="absolute inset-x-0 w-full bottom-16 mb-3 font-stolzl font-normal text-coolGray-50 text-sm pt-12">
            <div class="relative bg-coolGray-900 px-8 py-4 rounded-2xl shadow-md">
              <div class="absolute w-full -bottom-1 inset-x-0 flex flex-row justify-center items-center">
                <div class="w-4 h-4 bg-coolGray-900 rounded-sm transform-gpu rotate-45"></div>
              </div>
              <div>
                <div v-if="fallbackLang == 'DE'" class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1" @click="changeLang(fallbackLang)">DE</div>
                <div v-else class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1" @click="changeLang(fallbackLang)">EN</div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- LANGUAGE SWITCH-->

      <!-- PRODUCT SIZE -->
      <div v-if="this.$route.name == 'Products' && position == 'product' && commerce?.product && !isFetching && !notify && commerce?.product?.variants[0]?.name !== 'none'" class="h-10 flex-grow relative bg-coolGray-800 rounded-xl ml-2 mr-1 py-2 font-stolzl font-normal text-white text-sm flex flex-row justify-center items-center" >
        <div v-if="commerce?.product" class="pl-4 pr-2" @click="selectingSize = !selectingSize">{{sizeShortener(commerce.product.variants[selectedSizeIndex].name)}}</div>
        <div v-if="selectingSize" class="absolute inset-x-0 w-full bottom-12 mb-3 font-stolzl font-normal text-coolGray-50 text-sm pt-12">
          <div class="relative bg-coolGray-900 py-4 rounded-2xl shadow-md flex flex-col justify-center items-center">
            <div class="absolute w-full -bottom-1 inset-x-0 flex flex-row justify-center items-center">
                <div class="w-4 h-4 bg-coolGray-900 rounded-sm transform-gpu rotate-45"></div>
            </div>

            <div  v-for="(option, index) in selectableVariants" :key="option" class="relative w-full h-8 py-2 -ml-1 mb-1 flex flex-row justify-center" @click="setIndex(option.name)">
             <div v-if="index == filteredSelectedSizeIndex" class="w-full flex flex-row justify-center items-center">
               {{sizeShortener(option.name)}}
               <div class="ml-3 w-2 h-2 rounded-full bg-white"></div>
              </div>
             <div v-else class="w-full flex flex-row justify-center items-center">
               {{sizeShortener(option.name)}}
               <div class="ml-3 w-2 h-2"></div>
               </div>
            </div>

          </div>
        </div>
        <svg @click="selectingSize = !selectingSize" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 pr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
        </svg>
      </div>
      <div v-else-if="isFetching" class="animate-pulse h-10 flex-grow relative bg-coolGray-800 rounded-xl ml-2 mr-1 py-2 font-stolzl font-normal text-white text-sm flex flex-row justify-center items-center">
        {{text.loading}}
      </div>
      <!-- PRODUCT SIZE -->

      <!-- BUY BUTTON -->
      <div v-if="this.$route.name == 'Products' && position == 'product' && !notify" class="h-10 bg-coolGray-800 rounded-xl mr-2 ml-1 px-4 py-2 font-stolzl font-normal text-white text-sm flex flex-row justify-center items-center" @click="addToCart()">
        <p id="add" v-if="commerce?.product?.variants[selectedSizeIndex]?.quantityAvailable > 0">{{text.addToCart}}</p>
        <p v-else>{{text.notify}}</p>
      </div>
      <div v-else-if="position == 'product' && notify" class="flex-grow h-10 bg-coolGray-800 rounded-xl mr-2 ml-1 px-4 py-2 font-stolzl font-normal text-white text-sm flex flex-row justify-center items-center" @click="changeNotify()">
        <p>{{text.goBack}}</p>
      </div>
      <!-- BUY BUTTON -->

      <!-- SHOP -->
      <div v-if="this.$route.name == 'Categories'" class="bg-coolGray-800 rounded-xl py-2 font-stolzl font-normal text-coolGray-50 text-sm flex flex-col">
        <div class="group flex flex-row justify-center items-center mr-3 cursor-pointer" @click="browseNav('shop')">
          <div class="pl-4 pr-2">{{text.changeCategory}}</div>
        </div>
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="navigating && browse == 'shop'" class="absolute inset-x-0 w-full bottom-16 mb-3 font-stolzl font-normal text-coolGray-50 text-sm pt-12">
            <div class="relative bg-coolGray-900 px-8 py-4 rounded-2xl shadow-md">
              <div class="absolute w-full -bottom-1 inset-x-0 flex flex-row justify-center items-center">
                <div class="w-4 h-4 bg-coolGray-900 rounded-sm transform-gpu rotate-45"></div>
              </div>
              <div v-if="categories?.categories">
              <div v-for="category in categories.categories" :key="category" @click="navigate(category.id, category.slug)">
                <div class="py-2 cursor-pointer transition duration-200 ease-out transform-gpu hover:translate-x-1">{{category.name}}</div>
              </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- SHOP -->

      <!-- TEAM -->
      <div v-if="this.$route.name == 'Team' && filter?.length > 0 && currentFilter" class="bg-coolGray-800 rounded-xl py-2 font-stolzl font-normal text-coolGray-50 text-sm flex flex-col">
        <div class="group flex flex-row justify-center items-center mr-3 cursor-pointer" @click="browseNav('team')">
          <div v-if="currentFilter?.length > 0" class="pl-4 pr-2">Filter ({{currentFilter.length}})</div>
          <div v-else class="pl-4 pr-2">Filter</div>
        </div>
        <transition @enter="fadeInGrowEnter" @leave="fadeInGrowLeave" :css="false">
          <div v-if="navigating && browse == 'team'" class="absolute inset-x-0 w-full bottom-16 mb-3 font-stolzl font-normal text-coolGray-50 text-sm pt-12">
            <div class="relative bg-coolGray-900 px-8 py-4 rounded-2xl shadow-md">
              <div class="absolute w-full -bottom-1 inset-x-0 flex flex-row justify-center items-center">
                <div class="w-4 h-4 bg-coolGray-900 rounded-sm transform-gpu rotate-45"></div>
              </div>
              <div v-if="filter" class="flex flex-col">
                <div v-for="filter in filter" :key="filter" class="p-2 my-1 w-auto rounded flex-shrink"
                    :class="{'bg-coolGray-50 text-coolGray-900' : currentFilter.includes(filter.filterVal)}"
                @click="setTeamFilter(filter.filterVal)">{{teamText[filter.textId]}}</div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <!-- TEAM -->

      <!-- TEAM MEMBER -->
      <div v-if="this.$route.name == 'TeamMember'" class="bg-coolGray-800 rounded-xl py-2 font-stolzl font-normal text-coolGray-50 text-sm flex flex-col">
        <router-link :to="{ name: 'Team' }" class="group flex flex-row justify-center items-center mr-3 cursor-pointer">
          <div class="pl-4 pr-2">{{text.backToTeam}}</div>
        </router-link>
      </div>
      <!-- TEAM MEMBER -->

      <!-- TO CHECKOUT -->
      <div v-if="this.$route.name == 'Cart' && cartTotal.qty > 0" class="text-coolGray-50 font-normal text-sm">
        <div class="text-xs font-light">{{text.total}}</div>
        <div>{{cartTotal.total}}</div>
      </div>

      <router-link v-if="this.$route.name == 'Cart' && cartTotal.qty > 0" :to="{ name: 'Checkout' }" class="bg-coolGray-800 rounded-xl py-2 font-stolzl font-normal text-coolGray-50 text-sm flex flex-col">
        <div class="group flex flex-row justify-center items-center mr-3 cursor-pointer">
          <div class="pl-4 pr-2">{{text.toCheckout}}</div>
        </div>
      </router-link>

      <div v-if="this.$route.name == 'Cart' && cartTotal.qty > 0" class="absolute -bottom-14 h-12 text-xs font-light">
        <div v-if="channel == 'b2c'">{{text.cartInfo1}}</div>
        <div v-else>{{text.cartInfo2}}</div>
        <div v-if="cartTotal.trees == 1">{{text.cartInfo3}}{{cartTotal.trees}}{{text.cartInfo4}}</div>
        <div v-else-if="cartTotal.trees > 0">{{text.cartInfo5}}{{cartTotal.trees}}{{text.cartInfo6}}</div>
      </div>
      <!-- TO CHECKOUT -->

      <!-- AT CHECKOUT -->
      <div v-if="this.$route.name == 'Checkout'" class="w-full bg-coolGray-800 rounded-xl py-2 font-stolzl font-normal text-coolGray-50 text-sm flex flex-col" @click="switchToCart()">
        <div class="relative flex flex-row justify-center items-center mr-3 cursor-pointer">
          <div v-if="!showCartInCheckout && channel == 'b2c'" class="pl-4 pr-2">{{text.coupon}}</div>
          <div v-else-if="!showCartInCheckout && channel == 'b2b'" class="pl-4 pr-2">{{text.vatId}}</div>
          <div v-else class="pl-4 pr-2">{{text.backToCheckout}}</div>
        </div>
        <div v-if="!showCartInCheckout && channel == 'b2c'" class="absolute -bottom-14 h-12 text-xs font-light text-coolGray-900">{{text.couponInfo}}</div>
        <div v-if="!showCartInCheckout && channel == 'b2b'" class="absolute -bottom-14 h-12 text-xs font-light text-coolGray-900">{{text.couponInfo2}}</div>
      </div>
      <!-- AT CHECKOUT -->

      <!-- CART -->
      <div v-if="this.$route.name !== 'Checkout'" class="pr-2">
        <div @click="goToCart()">
          <div class="relative">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
            </svg>
            <div v-if="cart?.items.length !== 0" class="absolute -bottom-0.5 -right-0.5 w-2 h-2" :class="{'animate-ping' : addingItem}">
              <div  id="cartIndicator" class="w-full h-full rounded-full bg-white"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- CART -->

    </div>
  </div>
  <!-- MOBILE BAR -->
  <refetchPricesQuery />
  <getCategoriesQuery />
</div>
</template>

<script>
import anime from 'animejs/lib/anime.es.js';

import formatPrice from '../mixins/formatPrice.vue';

import getCategoriesQuery from '../graphql/graphcms/getCategoriesQuery.vue';
import refetchPricesQuery from '../graphql/saleor/refetchPricesQuery.vue';

export default {
  name: 'navbar',
  components: { getCategoriesQuery, refetchPricesQuery },
  mixins: [formatPrice],

  data() {
    return {
      navbar: true,
      lastScrollTop: 0,
      navigating: false,
      selectingSize: false,
      browse: '',
      hover: '',
      switchedChannel: false,
      currentTab: '',
    };
  },

  computed: {
    // graphql
    categories() {
      return this.$store.state.data.categories;
    },
    // data
    currentFilter() {
      return this.$store.state.data.currentFilter;
    },
    teamText() {
      return this.$store.state.lang.team[this.$store.state.lang.currentLang];
    },
    filter() {
      return this.$store.state.data.filter;
    },
    checkoutCompleted() {
      return this.$store.state.data.checkoutCompleted;
    },
    hideNav() {
      return this.$store.state.data.hideNav;
    },
    navBarBackground() {
      return this.$store.state.data.navBarBackground;
    },
    showCartInCheckout() {
      return this.$store.state.data.showCartInCheckout;
    },
    cartTotal() {
      let total = 0;
      let totalQty = 0;
      let trees = 0;
      this.cart.items.forEach((item) => {
        total += item.total;
        totalQty += item.qty;
        const metaIndex = item.meta.findIndex((x) => x.key === 'trees');
        if (metaIndex !== -1) {
          const metaVal = item.meta[metaIndex].value;
          trees += metaVal * item.qty;
        }
      });
      return { total: this.formatPrice(total), qty: totalQty, trees };
    },
    auth() {
      return this.$store.state.data.auth;
    },
    accountTabs() {
      return this.$store.state.data.accountTabs;
    },
    currentAccountTab() {
      return this.$store.state.data.currentTab;
    },
    text() {
      return this.$store.state.lang.navbar[this.$store.state.lang.currentLang];
    },
    lang() {
      return this.$store.state.lang.currentLang;
    },
    fallbackLang() {
      return this.$store.state.lang.fallbackLang;
    },
    navigatingMobile() {
      return this.$store.state.data.navigatingMobile;
    },
    addingItem() {
      return this.$store.state.data.addingItem;
    },
    channel() {
      return this.$store.state.data.channel;
    },
    cart() {
      return this.$store.state.data.cart;
    },
    position() {
      return this.$store.state.data.position;
    },
    commerce() {
      return this.$store.state.data.result2;
    },
    selectedColor() {
      return this.$store.state.data.selectedColor;
    },
    selectedSizeIndex() {
      return this.$store.state.data.selectedSizeIndex;
    },
    images() {
      if (this.selectedColor && this.colors.length > 0) {
        const filteredImages = [];
        for (let i = 0; i < this.$store.state.data.productImages.item.images.length; i += 1) {
          const fileName = this.$store.state.data.productImages.item.images[i].fileName.toLowerCase();
          if (fileName.includes(this.selectedColor.toLowerCase())) {
            filteredImages.push(this.$store.state.data.productImages.item.images[i]);
          }
        }
        return filteredImages;
      }
      return this.$store.state.data.productImages.item.images;
    },
    filteredSelectedSizeIndex() {
      return this.$store.state.data.filteredSelectedSizeIndex;
    },
    selectableVariants() {
      if (this?.commerce?.product) {
        if (this.colors.length > 0 && this.selectedColor) {
          const result = this.commerce.product.variants.filter((x) => x.name.includes(this.selectedColor));
          return result;
        }
        return this.commerce.product.variants;
      }
      return null;
    },
    colors() {
      const colors = [];
      if (this?.commerce?.product) {
        for (let i = 0; i < this.commerce.product.variants.length; i += 1) {
          for (let ii = 0; ii < this.commerce.product.variants[i].attributes.length; ii += 1) {
            if (this.commerce.product.variants[i].attributes[ii].attribute.name === 'color') {
              if (this.commerce.product.variants[i].attributes[ii].values.length > 0) {
                const value = this.commerce.product.variants[i].attributes[ii].values[0].name;
                if (!colors.includes(value)) {
                  colors.push(value);
                }
              }
            }
          }
        }
      }
      return colors;
    },
    notify() {
      return this.$store.state.data.notify;
    },
    isFetching() {
      return this.$store.state.data.isFetching;
    },
  },

  watch: {
    addingItem() {
      if (this.addingItem) {
        const that = this;
        setTimeout(() => {
          that.$store.commit('addingItem', false);
        }, 2000);
      }
    },
  },

  mounted() {
    this.$store.commit('setSelectedSizeIndex', 0);

    anime({
      targets: document.getElementById('nav'),
      easing: 'easeOutSine',
      duration: 500,
      translateY: [-15, 0],
      delay: 1000,
      opacity: [0, 1],
    });
  },

  methods: {

    // ----------------------------------------------//
    // -----------------ANIMATIONS-------------------//
    // ----------------------------------------------//

    addingToCart() {
      const el = document.getElementById('add');
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 350,
        opacity: [0, 1],
      });
    },

    showMenu() {
      const menu = document.getElementById('menu');
      this.$store.commit('setNavigatingMobile', true);

      const tl = anime.timeline({
        easing: 'easeInOutCubic',
        duration: 250,
      });
      tl
        .add({
          targets: menu,
          opacity: [0, 1],
        });
    },

    closeMenu() {
      const menu = document.getElementById('menu');
      const that = this;

      const tl2 = anime.timeline({
        easing: 'easeInOutCubic',
        duration: 250,
      });
      tl2
        .add({
          targets: menu,
          opacity: [1, 0],
          complete() {
            that.$store.commit('setNavigatingMobile', false);
          },
        });
    },

    fadeInGrowEnter2(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 100,
        opacity: [0, 1],
        height: ['0px', `${el.offsetHeight}px`],
        complete: done,
      });
    },

    fadeInGrowLeave2(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 100,
        opacity: [1, 0],
        height: [`${el.offsetHeight}px`, '0px'],
        complete: done,
      });
    },

    fadeInGrowEnter(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 100,
        opacity: [0, 1],
        translateZ: 0,
        complete: done,
      });
    },

    fadeInGrowLeave(el, done) {
      anime({
        targets: el,
        easing: 'easeInOutCubic',
        duration: 100,
        opacity: [1, 0],
        translateZ: 0,
        complete: done,
      });
    },

    // ----------------------------------------------//
    // ------------------METHODS---------------------//
    // ----------------------------------------------//

    goHomeOrScroll() {
      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home' });
      } else {
        window.scrollTo({ top: 0 });
      }
    },

    setTeamFilter(filterVal) {
      this.$store.commit('setTeamFilter', filterVal);
    },

    setTab(tab) {
      this.$store.commit('setCurrentTab', tab);
      this.navigating = false;
      this.browse = '';
    },

    changeNotify() {
      this.$store.commit('setNotify', !this.notify);
      this.$store.commit('setSubmitted', false);
    },

    sizeShortener(size) {
      let short = size.replace(this.selectedColor, '');
      short = short.replace('/', '');
      short = short.trim();
      return short;
    },

    changeLang(lang) {
      this.$store.commit('setLang', lang);
      this.navigating = false;
      this.hover = '';
    },

    navigate(id, slug) {
      this.$router.push({ name: 'Categories', params: { categoryId: id, slug } });
      window.scrollTo({ top: 0 });
      this.navigating = false;
      this.hover = '';
      if (this.navigatingMobile === true) {
        this.closeMenu();
        this.currentTab = '';
      }
    },

    toggleTab(tab) {
      if (this.currentTab === tab) {
        this.currentTab = '';
      } else {
        this.currentTab = tab;
      }
    },

    browseNav(tag) {
      if (this.browse !== tag) {
        this.browse = tag;
        this.navigating = true;
      } else if (!this.navigating) {
        this.navigating = true;
      } else {
        this.browse = '';
        this.navigating = false;
      }
    },

    resetBlur() {
      if (this.navigating) {
        this.navigating = false;
      }
      if (this.selectingSize) {
        this.selectingSize = false;
      }
    },

    setIndex(variantName) {
      const index = this.commerce.product.variants.findIndex((x) => x.name === variantName);
      const filteredIndex = this.selectableVariants.findIndex((x) => x.name === variantName);
      this.$store.commit('setSelectedSizeIndex', index);
      this.$store.commit('setFilteredSelectedSizeIndex', filteredIndex);
      this.selectingSize = false;
    },

    goBack() {
      window.history.back();
    },

    setChannel(payload) {
      this.$store.commit('setChannel', payload.channel);
      if (payload.channel === 'b2b') {
        if (payload.switch) {
          this.switchedChannel = true;
        } else {
          this.switchedChannel = false;
        }
      } else {
        this.switchedChannel = false;
      }
    },

    goToCart() {
      if (this.cartTotal.qty > 0) {
        this.$router.push({ name: 'Cart' });
      }
    },

    switchToCart() {
      this.$store.commit('showCartInCheckout', !this.showCartInCheckout);
    },

    addToCart() {
      if (this?.commerce?.product?.variants[this.selectedSizeIndex]?.quantityAvailable > 0) {
        const variantSizeIndex = this.selectedSizeIndex;
        const productName = this.commerce.product.name;
        const variantID = this.commerce.product.variants[variantSizeIndex].id;
        const variantName = this.commerce.product.variants[variantSizeIndex].name;
        const { slug } = this.commerce.product;
        const price = this.commerce.product.variants[variantSizeIndex].pricing.price.gross.amount;
        const { channel } = this;
        const meta = this.commerce.product.variants[variantSizeIndex].metadata;
        const { url } = this.images[0];
        this.$store.commit('addItem', {
          name: productName, id: variantID, url, slug, variantName, price, channel, meta,
        });
        this.$store.commit('addingItem', true);
        this.addingToCart();
      } else {
        this.$store.commit('setNotify', true);
      }
    },

  },
};
</script>
